<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
			<div class="home">
				<div  @click="go('My')">我的主页</div>
				<div><</div>
				<div style="color: #E66617;">我的会员</div>
			</div>
			<div class="off">
				<div v-for="(item,index) in userInfo" :key="index">
					<div class="offline"  v-if="item.level != 4">
						<div :class="item.level == 2?'vip':item.level == 3?'svip':item.level == 5?'month':'season'">
							<div class="box">
								<div class="vip-name">{{ item.type_name }}</div>
								<div class="vip-text">有效期：{{item.start_time}}-{{item.end_time}}</div>
							</div>
						</div>
					</div>
					<div class="offline"  v-if="item.level == 4">
						<div class="original">
							<div class="box">
								<div class="vip-name">{{ item.type_name }}</div>
								<div class="vip-text">有效期：{{item.start_time}}-{{item.end_time}}</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>

			<info />
			<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
			<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import {
		userVipInfo
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				vipInfo: "", //vip 信息
				svipInfo: "", //svip 信息
				userInfo: '', //用户会员信息
				vipShow: false,
				svipShow: false,
				seasonShow: false,
				monthShow: false,
				originalShow: false,
				type_id: "",
			};
		},
		created() {
			this.getUserVipInfo();
		},
		methods: {
			//获取用户会员信息
			getUserVipInfo() {
				userVipInfo({}).then((res) => {
					console.log(res);
					// this.vipInfo = res.data.vipInfo
					// this.svipInfo = res.data.svipInfo
					this.userInfo = res.data.user_levels
					this.vipInfo = res.data.vip_levels
			
			
				});
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;

			div {
				margin-right: 7px;
			}
		}
		.off {
			margin-top: 15px;
			padding: 0 10px;
			.offline {
				padding: 12px;
				font-size: 12px;
				background-color: #FFFFFF;
				color: #08243C;
				display: flex;
				.original {
					width: 345px;
					height: 142px;
					background: url("../../assets/original.png") no-repeat;
					background-size: 100% 100%;
					background-size: cover;
					position: relative;
				
					.box {
						width: 92%;
						height: 120px;
						border: 1px solid #dfc58b;
						border-radius: 6px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				
						.vip-name {
							font-size: 34px;
							font-weight: bold;
							font-style: italic;
							color: #08243c;
							line-height: 36px;
							background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-top: 19px;
							margin-left: 31px;
						}
				
						.vip-text {
							font-size: 14px;
							font-weight: 400;
							color: #b49961;
							line-height: 14px;
							margin-left: 31px;
							margin-top: 35px;
						}
					}
				}
				
				.vip {
					width: 345px;
					height: 142px;
					background: url("../../assets/vip.png") no-repeat;
					background-size: 100% 100%;
					background-size: cover;
					position: relative;
				
					.box {
						width: 92%;
						height: 120px;
						border: 1px solid #dfc58b;
						border-radius: 6px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				
						.vip-name {
							font-size: 34px;
							font-weight: bold;
							font-style: italic;
							color: #08243c;
							line-height: 36px;
							background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-top: 19px;
							margin-left: 31px;
						}
				
						.vip-text {
							font-size: 14px;
							font-weight: 400;
							color: #b49961;
							line-height: 14px;
							margin-left: 31px;
							margin-top: 35px;
						}
					}
				}
				
				.svip {
					width: 345px;
					height: 142px;
					background: url("../../assets/svip.png") no-repeat;
					background-size: 100% 100%;
					background-size: cover;
					position: relative;
				
					.box {
						width: 92%;
						height: 120px;
						border: 1px solid #dfc58b;
						border-radius: 6px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				
						.vip-name {
							font-size: 34px;
							font-weight: bold;
							font-style: italic;
							color: #08243c;
							line-height: 36px;
							background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-top: 19px;
							margin-left: 31px;
						}
				
						.vip-text {
							font-size: 14px;
							font-weight: 400;
							color: #b49961;
							line-height: 14px;
							margin-left: 31px;
							margin-top: 35px;
						}
					}
				}
				
				.month {
					width: 345px;
					height: 142px;
					background: url("../../assets/month.png") no-repeat;
					background-size: 100% 100%;
					background-size: cover;
					position: relative;
				
					.box {
						width: 92%;
						height: 120px;
						border: 1px solid #dfc58b;
						border-radius: 6px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				
						.vip-name {
							font-size: 34px;
							font-weight: bold;
							font-style: italic;
							color: #08243c;
							line-height: 36px;
							background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-top: 19px;
							margin-left: 31px;
						}
				
						.vip-text {
							font-size: 14px;
							font-weight: 400;
							color: #b49961;
							line-height: 14px;
							margin-left: 31px;
							margin-top: 35px;
						}
					}
				}
				
				.season {
					width: 345px;
					height: 142px;
					background: url("../../assets/season.png") no-repeat;
					background-size: 100% 100%;
					background-size: cover;
					position: relative;
				
					.box {
						width: 92%;
						height: 120px;
						border: 1px solid #dfc58b;
						border-radius: 6px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
				
						.vip-name {
							font-size: 34px;
							font-weight: bold;
							font-style: italic;
							color: #08243c;
							line-height: 36px;
							background: linear-gradient(-90deg, #946a44 0%, #e6a75d 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-top: 19px;
							margin-left: 31px;
						}
				
						.vip-text {
							font-size: 14px;
							font-weight: 400;
							color: #b49961;
							line-height: 14px;
							margin-left: 31px;
							margin-top: 35px;
						}
					}
				}
			}
			

		}
		
	}
</style>
